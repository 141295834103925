@import '../_var.less';

@atom: InputDate;

@dim-width: 100%;

.@{atom}--separatedValue {
    display: none;
}
.@{atom}--separatedCtn {
    max-width: 300px;

    .@{atom}--separatedBtnCtn {
        width: @dim-width;

        display: flex;
        flex-flow: row nowrap;
        margin-bottom: @dim-sm;

        .@{atom}--separatedBtn {
            flex: 1;

            input[type="radio"]{
                display: none;

                &:checked ~ label {
                    background: @color-primary;
                    color: @color-white;
                }

                &:disabled ~ label {
                    background: @color-disabled;
                    color: @color-label;
                    border-color: @color-grey !important;
                    user-select: none;
                    cursor: inherit;
                }
            }
            
            label {
                background: @color-white;    
                padding: @dim-xs @dim-sm;
                border: solid 1px #d0e7f6;
                cursor: pointer;
                font-size: @font-xxs;
                width: 100% !important;
                display: inline-block;
                text-align: center;
                font-weight: 800;
            }

            @dim-radius: 5px;

            &:not(:first-child) {
                label {
                    border-left: none;
                    width: calc(100% - @dim-sm * 2 - 1px);
                }
            }
            &:first-child {
                label {
                    border-radius: @dim-radius 0 0 @dim-radius;
                }
            }
            &:last-child {
                label {
                    border-radius: 0 @dim-radius @dim-radius 0;
                }
            }
        }
    }

    .@{atom}--separated {
        width: @dim-width;
        display: flex;
        flex-flow: row nowrap;

        .InputContainer {

            &:nth-child(1) {
                width: calc(25%);
                .InputText {
                    border-radius: 2px 0 0 2px;
                    border-right: dashed 1px #c6e9f7;
                    z-index: 2;
                }
            }
            &:nth-child(2) {
                width: calc(25%);
                .InputText {
                    border-radius: 0;
                    z-index: 1;
                    border-right: dashed 1px #c6e9f7;
                    border-left: none;
                }
            }
            &:nth-child(3) {
                width: calc(50%);
                .InputText {
                    border-radius: 0 2px 2px 0;
                    border-left: none;
                }
            }
            
            .Label {
                text-align: center;
                margin-right: 0;
                display: none;
            }
            .InputText {
                text-align: center;
                // width: calc(100% - @dim-sm);
                width: 100%;
                margin-bottom: @dim-xs;
                cursor: text;
                
                &:disabled::-webkit-input-placeholder {
                    color: rgba(0,0,0,0);
                }
            }
        }
    }
}